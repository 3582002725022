.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin: 3vw 0 0 0;
}

/* Screens > 2000px wide */
@media screen and (min-width: 2000px) {
  .projects-container {
    margin: 0 auto;
    width: 85%;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}
