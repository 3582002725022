.fadeIn {
  opacity: 1;
  transition: opacity 0.5s 0.5s;
}

.about
{
  align-items: start;
  background-color: white;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 50fr 50fr;
  margin: 20px;
}

.left {
  grid-column: 1;
}

.links {
  grid-column: 2;
}

a {
  text-decoration: underline rgb(211, 211, 211);
}

.container {
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
  color: rgb(102, 102, 102);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: calc(max(1vw, 20px));
  line-height: calc(max(1vw, 1.5rem));
  padding: 20px;
  text-align: left;
  /* TODO: this margin would be better expressed as a defined grid
           with a column gap */
  margin-bottom: 20px;
}

.invisible {
  opacity: 0;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Screens < 800px wide */
@media screen and (max-width: 800px) {
  .about {
    grid-template-columns: 1fr;
    grid-template-rows: auto 40fr;
    column-gap: 10px;
    row-gap: 0px;
  }
  .bio {
    grid-column: 1;
    grid-row: 1;
  }
  .links {
    grid-column: 1;
    grid-row: 2;
  }
}

/* Screens > 1000px wide */
@media screen and (min-width: 1000px) {
  .about {
    margin: 2vw;
    line-height: normal;
  }
}

/* Screens > 2000px wide */
@media screen and (min-width: 2000px) {
  .about {
    margin: 2vw 5vw;
    line-height: normal;
  }
}
