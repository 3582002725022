.secondary-header {
  margin: 4vw 5vw;
  text-align: left;
}

.header-link {
  color: rgb(202, 202, 202);
}

.header-title {
  animation: GradientShift 4s ease infinite;
  -moz-animation: GradientShift 4s ease infinite;
  -webkit-animation: GradientShift 4s ease infinite;
  -webkit-background-clip: text;
  background-image: linear-gradient(104deg, #049bb0, #a5bb43);
  background-size: 200% 200%;
  color: var(--placeholder-green);
  -webkit-text-fill-color: transparent;
}

h1 {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 5vw;
  margin: 0;
  padding-left: 2vw;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

#back-arrow {
  display: inline-block;
  height: 3vw;
  padding-right: 1vw;
  color: rgb(202, 202, 202);
}

@-webkit-keyframes GradientShift {
  0% {
    background-position: 0% 65%;
  }
  50% {
    background-position: 100% 36%;
  }
  100% {
    background-position: 0% 65%;
  }
}
@-moz-keyframes GradientShift {
  0% {
    background-position: 0% 65%;
  }
  50% {
    background-position: 100% 36%;
  }
  100% {
    background-position: 0% 65%;
  }
}
@keyframes GradientShift {
  0% {
    background-position: 0% 65%;
  }
  50% {
    background-position: 100% 36%;
  }
  100% {
    background-position: 0% 65%;
  }
}

/* Screens > 1000px wide */
@media screen and (min-width: 1000px) {
  .secondary-header {
    margin: 2vw 3vw;
  }

  #back-arrow {
    height: 2vw;
  }

  h1 {
    font-size: 3vw;
  }
}

/* Screens < 600px wide */
@media screen and (max-width: 600px) {
  .secondary-header {
    margin: 5vw 7vw;
  }

  #back-arrow {
    height: 6vw;
  }

  h1 {
    font-size: 10vw;
  }

  h1:not(.header-title) {
    display: none;
  }
}
