#nav-container
{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 6vw;
  position: fixed;
  opacity: 0;
  right: 0;
  z-index: 1;
}
#title
{
  align-items: flex-end;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-family: 'Rubik', sans-serif;
  font-size: 15vw;
  height: 100%;
  justify-content: left;
  line-height: 13vw;
  margin: 0;
  padding: 10vw;
  position: relative;
  opacity: 0;
  text-align: left;
}
.home
{
  background-color: var(--placeholder-green);
  box-sizing: border-box;
  height: 100%;
}
.nav-link
{
  color: white;
  font-size: 4.5vw;
  padding: 0 2vw;
  text-decoration: none;
}
#home-video
{
  box-sizing: border-box;
  height: calc(100vh - calc(100vh - 100%));
  left: 0;
  margin: 0;
  object-fit: cover;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

/* Screens > 1000px wide */
@media screen and (min-width: 1000px) {
  #title {
    font-size: 10rem;
    line-height: 9rem;
    padding: 5rem;
  }

  .nav-link {
    font-size: 2rem;
    padding: 0 1rem;
  }

  #nav-container {
    padding: 4rem;
  }
}

/* Screens > 2000px wide */
@media screen and (min-width: 2000px) {
  #title {
    font-size: 12rem;
    line-height: 11rem;
    padding: 7rem;
  }

  .nav-link {
    font-size: 1.5rem;
  }

  .blur {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }
}
